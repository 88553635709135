<template>
	<div>
		<v-card dense outlined>
			<v-list 
				three-line
			>
				<v-subheader>Reportes</v-subheader>
				<v-list-item-group>
					<v-list-item>
						<v-list-item-action>
							<v-btn
								fab
								small
								@click="OpenDialogBrix()"
							>
								<v-icon 
									color="red darken-2"
								>
									far fa-file-pdf
								</v-icon>
							</v-btn>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								Reporte de Brix
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-card>

		<!-- dialog para reporte de brix -->
		<v-dialog
			v-if="dialogReportBrix"
			v-model="dialogReportBrix"
			width="400"
			persistent
		>
			<v-card dense outlined class="mx-auto">
				<v-card-title>
					Reporte de Brix
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col>
							<s-date
								label="Fecha"
								v-model="filter.DateBegin"
							></s-date>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-select-definition
								:def="1173"
								label="Cultivo"
								v-model="filter.TypeCultive"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="success"
						text
						@click="DownloadReportBrix()"
					>
						Descargar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="orange"
						text
						@click="dialogReportBrix = false"
					>
						Cancelar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling";

	export default {
		data() {
			return {
				dialogReportBrix: false,
				filter: {}
			}
		},

		methods: {
			OpenDialogBrix() {
				this.dialogReportBrix = !this.dialogReportBrix;
			},

			DownloadReportBrix()
			{
				_sFrozenSampling.samplingRPdf(this.filter, this.$fun.getUserID()).then((r) => {
					this.$fun.downloadFile(
					r.data,
					this.$const.TypeFile.PDF,
					"Muestreo recepcion congelado"
					);
				});
			}
		},
	}
</script>
